<template>
  <div class="transaction-overview">
    <div class="title">
      Overview
    </div>
    <c-inline-notification v-if="isChargebackTransaction() || isReserveTransaction()" variant="info" size="st">
      {{ bannerInfo() }}
    </c-inline-notification>
    <div class="fields" v-if="transaction">
      <div class="column">
        <FieldValue
          :field-value="formatDateWithTimezone(transaction.created_at.toString())"
          :field-name="$t('fieldNames.transactionDate')"
        />

        <FieldValue
          v-if="settlement"
          :field-value="formatDateWithTimezone(settlement.settled_at.toString())"
          :field-name="$t('fieldNames.settlementDate')"
        />

        <template v-if="transaction.settlement_id">
          <FieldValue
            v-if="showLinkForSettlement()"
            :field-value="transaction.settlement_id"
            :field-name="$t('fieldNames.settlementId')"
            class-name="settlement-id"
            :on-click="navigateToDepositDetails"
          />
          <FieldValue
            v-else
            :field-value="null"
            :field-name="$t('fieldNames.settlementId')"
            class-name="settlement-id"
          />
        </template>

        <template v-if="!!getDisputeId()">
          <FieldValue
            :field-value="getDisputeId()"
            :field-name="$t('fieldNames.disputeId')"
            class-name="dispute-id"
            :on-click="navigateToDisputeDetails"
          />
        </template>
      </div>
      <div class="column">
        <FieldValue
          v-if="['DEBIT', 'RESERVE'].includes(transaction.type)"
          :field-value="getAmount()"
          :field-name="$t('fieldNames.amount')"
        />
        <FieldValue
          v-if="transaction.type === 'REFUND'"
          :field-value="getAmount()"
          :field-name="$t('fieldNames.refundAmount')"
        />
        <FieldValue
          v-if="['ACH_RETURN', 'PAYMENT_RETURN', 'PAYMENT_REVERSAL'].includes(transaction.type)"
          :field-value="getAmount()"
          :field-name="getAmountFieldName()"
        />
        <FieldValue
          v-if="!isChargebackTransaction()"
          :field-value="getFee()"
          :field-name="$t('fieldNames.fees')"
        />
        <FieldValue
          v-if="['DEBIT'].includes(transaction.type) && transaction.payfac === 'ADYEN'"
          :field-value="getReserveAmount()"
          :field-name="$t('fieldNames.reserveDeduction')"
          :tooltip="$t('fieldTooltips.reserveDeduction')"
        />
        <FieldValue
          v-if="['DEBIT', 'RESERVE'].includes(transaction.type)"
          :field-value="getNetAmount()"
          :field-name="$t('fieldNames.netAmount')"
        />
        <FieldValue
          v-if="sourceTransaction"
          :field-value="getParentTransactionInfo()"
          :field-name="$t('fieldNames.parentTransaction')"
        >
          <div class="parent-transaction-container">
            <div class="source-transaction" @click="goToSourceTransaction" :id="getElementId('parent_transaction')">
              {{ getParentTransactionInfo() }}
            </div>
          </div>
        </FieldValue>
      </div>
    </div>
  </div>
</template>

<script>
import FieldValue from "@/components/common/FieldValue.vue";
import Formatter from "@/app/utils/common/functions/formatter";
import {
  TransactionResponseDto,
  SettlementWithBreakdownDto,
  DepositSubType,
  TransactionType,
  TransactionState,
  DisputeResponseDto
} from "@/api/paymentApi";
import {mapGetters} from "vuex";
import {navigateWithParam} from "@/router";
import {PendoPrefixes} from "@/app/utils/common/constants";
import {RouteConstants} from "@/router/routeConstants";

export default {
  name: "TransactionOverview",
  components: {FieldValue},
  props: {
    transaction: {
      type: TransactionResponseDto,
      required: true,
    },
    sourceTransaction: {
      type: TransactionResponseDto,
      required: true,
    },
    settlement: {
      type: SettlementWithBreakdownDto,
      required: true
    },
    dispute: {
      type: DisputeResponseDto,
      required: true,
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId', 'getMerchantCurrency']),
  },
  methods: {
    navigateToDepositDetails() {
      navigateWithParam(RouteConstants.DEPOSIT_DETAILS, {
        settlementId: this.transaction.settlement_id,
        data: null
      }, this.$route.query)
    },
    navigateToDisputeDetails() {
      if (!this.getDisputeId()) {
        return;
      }
      navigateWithParam(RouteConstants.DISPUTE_DETAILS, {
        disputeId: this.getDisputeId(),
        data: null
      }, this.$route.query)
    },
    isChargebackTransaction() {
      return this.transaction.type === TransactionType.CHARGEBACKDEBIT
          || this.transaction.type === TransactionType.CHARGEBACKCREDIT
          || this.transaction.type === TransactionType.CHARGEBACKFEE;
    },
    isReserveTransaction() {
      return this.transaction.type === TransactionType.RESERVE
        || this.transaction.type === TransactionType.RESERVEADJUSTMENTDEBIT
        || this.transaction.type === TransactionType.RESERVEADJUSTMENTCREDIT
        || this.transaction.type === TransactionType.RESERVETRANSFER;
    },
    isDunningAttempt() {
      return this.transaction.tags?.is_dunning_attempt;
    },
    bannerInfo() {
      return this.$t('transactions.message.' + this.transaction.type)
    },
    formatDateWithTimezone(date) {
      return Formatter.format({type: 'zonedDateWithTime', data: date})
    },
    formatCurrency(data, currencyCode) {
      return Formatter.format({type: 'currency', data, currencyCode}) + " " + currencyCode
    },
    getNetAmount() {
      if([TransactionState.FAILED, TransactionState.NOTREFUNDED, TransactionState.INITIATIONATPAYFACFAILED].includes(this.transaction.state)) {
        return null;
      }
      return this.appendValueInMerchantCurrency(
        this.formatCurrency(this.transaction.net_amount, this.transaction.currency),
        this.transaction.net_amount_in_merchant_currency);
    },
    getParentTransactionInfo() {
      if(this.isChargebackTransaction()) {
        return this.transaction.source_transaction_id;
      } else if(this.isDunningAttempt()) {
        return this.transaction.tags?.parent_transaction_id;
      } else {
        return this.getSourceTransactionAmount();
      }
    },
    getSourceTransactionAmount() {
      let netAmount = this.sourceTransaction.amount;
      return this.formatCurrency(netAmount, this.sourceTransaction.currency)
    },
    getDisputeId() {
      if(this.isChargebackTransaction() && this.transaction.tags && this.transaction.tags.dispute_id) {
        return this.transaction.tags.dispute_id;
      }

      //If the transaction is disputed, we will have disputeId for it.
      if(this.transaction.type === TransactionType.DEBIT && this.dispute) {
        return this.dispute.id;
      }
    },
    getFee() {
      let fee = this.transaction.fee;
      if (!fee || [TransactionState.FAILED, TransactionState.NOTREFUNDED, TransactionState.INITIATIONATPAYFACFAILED].includes(this.transaction.state)) {
        return null;
      }
      return this.appendValueInMerchantCurrency(
        this.formatCurrency(fee, this.transaction.currency),
        this.transaction.fee_in_merchant_currency);
    },
    getReserveAmount() {
      let transactionReserve = this.transaction.transaction_reserve;
      if (!transactionReserve || [TransactionState.FAILED, TransactionState.NOTREFUNDED, TransactionState.INITIATIONATPAYFACFAILED].includes(this.transaction.state)) {
        return null;
      }
      return this.appendValueInMerchantCurrency(
        this.formatCurrency(transactionReserve.amount, this.transaction.currency),
        transactionReserve.amount_in_merchant_currency);
    },
    appendValueInMerchantCurrency(prefix, value) {
      if (prefix && value 
            && [TransactionType.DEBIT, TransactionType.REFUND].includes(this.transaction.type)
            && this.transaction.currency != this.getMerchantCurrency) {
            let amountInMerchantCurrency = this.formatCurrency(value, this.getMerchantCurrency);
            return prefix + " (" + amountInMerchantCurrency +")";
      }
      return prefix;
    },
    getAmount() {
      return this.appendValueInMerchantCurrency(
        this.formatCurrency(this.transaction.amount, this.transaction.currency), 
        this.transaction.amount_in_merchant_currency);
    },
    getAmountFieldName() {
      switch(this.transaction.type) {
        case TransactionType.REFUND:
        case TransactionType.PAYMENTRETURN:
        case TransactionType.PAYMENTREVERSAL:
        case TransactionType.ACHRETURN:
          return 'Refund Amount';
        case TransactionType.CHARGEBACKDEBIT:
        case TransactionType.CHARGEBACKCREDIT:
          return 'Amount (Chargeback)';
        case TransactionType.CHARGEBACKFEE:
          return 'Amount (Chargeback Fee)';
        default:
          return 'Amount';
      }
    },
    goToSourceTransaction() {
      navigateWithParam(RouteConstants.TRANSACTION_DETAILS,
          {transactionId: this.sourceTransaction.id, data: this.sourceTransaction},
          {
            ...this.$route.query,
            consumerId: this.sourceTransaction.consumer.id,
          })
    },
    showLinkForSettlement() {
      return this.getMerchantSettlementDeposit(this.settlement).amount !== undefined;
    },
    getMerchantSettlementDeposit(settlement) {
      return settlement?.deposits?.find(d => d.subType === DepositSubType.MERCHANT) || {}
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id;
    },
  },
}
</script>

<style lang="scss">
$c-inline-notification: '.c-inline-notification';
#{$c-inline-notification} {
  margin-top: 12px;

  &__container {
    text-align: left;
  }
}
.transaction-overview {
  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight-bold;
  }

  & .warning {
    margin-top: 12px;
    display: flex;
    background: $info_50;
    border: 0.5px solid $info_100;
    border-radius: 4px;
    padding: 6px;
    gap: 10px;
    color: $primary_600;
    line-height: 18px;

    & .warning-text {
      color: $neutral-800;
      height: 18px;
      font-size: 12px;
    }
  }

  & .fields {
    display: flex;

    & .column {
      text-align: left;
      flex: 1;
    }

    & .consumer-email {
      & a {
        text-decoration: none;
      }
    }

    & .parent-transaction-container {
      display: flex;
      gap: 4px;

      & .source-transaction {
        color: $primary_600;
        font-weight: $weight_medium;
        cursor: pointer;
      }
    }

    & .respond-by {
      & .field-value {
        color: #DE3618;
      }
    }
  }
}
</style>