<template>
  <div class="main-container">
    <SidebarNav @update:sidebarNavCollapsed="sidebarNavCollapsed = $event" />
    <SearchBar :sidebar-nav-collapsed="sidebarNavCollapsed" />
    <div
      class="dashboard-container"
      :class="{['dashboard-container-sidebar-collapse']: sidebarNavCollapsed}"
      :key="sidebarNavCollapsed + windowWidth"
    >
      <div class="dashboard-sub-container" ref="contentToConvert">
        <MigrationHelper v-if="!isAdminMode && isEligibleForMigration" />
        <c-notification
          variant="danger"
          size="sm"
          :title="negativeReserveTitle()"
          v-if="getCountry === 'USA' && chargebackReserveAmount < 0"
          style="text-align: left"
        >
          <div slot="main">
            {{ $t('dashboard.notifications.negativeReserve.description') }}
            <div class="see-cf-details" @click="navigateToAddFunds()">
              {{ $t('dashboard.notifications.negativeReserve.addFunds') }}
            </div>
          </div>
        </c-notification>
        <br v-if="chargebackReserveAmount && complianceForm" />
        <c-notification
          variant="danger"
          size="sm"
          :title="$t('dashboard.notifications.compliance.title')"
          v-if="complianceForm"
          style="text-align: left"
        >
          <div slot="main">
            {{ $t('dashboard.notifications.compliance.reviewForm') }}
            <div class="see-cf-details" @click="navigateToComplianceForSigning()">
              {{ $t('dashboard.notifications.compliance.seeDetails') }}
            </div>
          </div>
        </c-notification>
        <div class="table-header">
          <div class="dashboard-title">
            {{ $t('dashboard.title') }}
          </div>
          <div class="export-buttton">
            <c-button
              variant="primary-outline"
              @click="exportToPDF()"
              data-html2canvas-ignore="true"
            >
              <img alt="export" class="download-icon" src="@/app/assets/images/download.svg" />
              {{ $t('dashboard.export') }}
            </c-button>
          </div>
        </div>
        <div>
          <c-date-time-picker
            :value="dateSelected === null ? undefined : dateSelected"
            class="date-picker"
            :max-date="dateSelectorLimit(-3)[0]"
            :min-date="dateSelectorLimit(-3)[1]"
            :use-time="false"
            :is-range="false"
            :zone="getTimezoneName"
            @update="applyFilterDatepicker"
          >
            <c-button slot="trigger" class="filter-button">
              <span class="datepicker-date">
                {{ DatepickerDate() }} {{ DatepickerDay() }}
              </span>
              <img alt="calender" class="filter-icon" src="@/app/assets/images/dropdown.svg" />
            </c-button>
          </c-date-time-picker>
        </div>

        <div class="dashboard-today-data">
          <Statistics
            :statistics-type="Metric.GROSSVOLUME" :interval-type="Interval.HOUR" :disable-date-range="false"
            :date-filter="datepickerFilter" :key="keyPrefixDatepickerFilter + Metric.GROSSVOLUME"
          />
          <div class="sub-data-container">
            <div class="sub-data">
              <Statistics
                :statistics-type="Metric.REFUNDS" :interval-type="Interval.HOUR" :disable-date-range="false"
                :disabled-chart="true" :date-filter="datepickerFilter" :key="keyPrefixDatepickerFilter + Metric.REFUNDS"
                :on-click="() => navigateTo('transactions', 'today', 'REFUND')"
              />
              <Statistics
                :statistics-type="Metric.CHARGEBACKS" :interval-type="Interval.HOUR" :disable-date-range="false"
                :disabled-chart="true" :date-filter="datepickerFilter" :key="keyPrefixDatepickerFilter + Metric.CHARGEBACKS"
                :on-click="() => navigateTo('disputes', 'today')"
              />
            </div>
            <div class="sub-data">
              <Statistics
                :statistics-type="Metric.DEPOSITS" :interval-type="Interval.HOUR" :disable-date-range="false"
                :disabled-chart="true" :date-filter="datepickerFilter" :key="keyPrefixDatepickerFilter + Metric.DEPOSITS"
                :on-click="() => navigateTo('deposits', 'today')"
              />
              <Statistics
                :statistics-type="Metric.TRANSACTIONS" :interval-type="Interval.HOUR" :disable-date-range="false"
                :disabled-chart="true" :date-filter="datepickerFilter" :key="keyPrefixDatepickerFilter + Metric.TRANSACTIONS"
                :on-click="() => navigateTo('transactions', 'today', 'ALL')"
              />
            </div>
          </div>
        </div>

        <div class="insight-header">
          Insights
        </div>

        <TableFilterOption
          :disabled="false"
          table-label="insights"
          :enable-filters="['dateFilter']"
          @childApplyFilter="applyFilterInsights"
          @childClearFilter="applyFilterInsights"
        />

        <div class="insight-container">
          <Statistics
            :statistics-type="Metric.GROSSVOLUME" :interval-type="Interval.DAY" :date-filter="dateFilter"
            :key="keyPrefixDateFilter + Metric.GROSSVOLUME"
          />
          <Statistics
            :statistics-type="Metric.REFUNDS" :interval-type="Interval.DAY" :date-filter="dateFilter"
            :key="keyPrefixDateFilter + Metric.REFUNDS"
          />
          <Statistics
            :statistics-type="Metric.PAYMENTACCEPTANCERATE" :disabled="true" :date-filter="dateFilter"
            :key="keyPrefixDateFilter + Metric.PAYMENTACCEPTANCERATE"
          />
          <Statistics
            :statistics-type="Metric.CHARGEBACKRATE" :date-filter="dateFilter"
            :key="keyPrefixDateFilter + Metric.CHARGEBACKRATE"
          />
          <Statistics
            :statistics-type="Metric.CHARGEBACKS" :date-filteer="dateFilter" :date-filter="dateFilter"
            :key="keyPrefixDateFilter + Metric.CHARGEBACKS"
          />
          <Statistics
            :statistics-type="Metric.INQUIRIES" :date-filter="dateFilter"
            :key="keyPrefixDateFilter + Metric.INQUIRIES"
          />
          <Statistics
            :statistics-type="Metric.DEPOSITS" :interval-type="Interval.DAY" :date-filter="dateFilter"
            :key="keyPrefixDateFilter + Metric.DEPOSITS"
          />
          <Statistics
            :statistics-type="Metric.TOTALACTIVECUSTOMERS" :disabled="true" :date-filter="dateFilter"
            :key="keyPrefixDateFilter + Metric.TOTALACTIVECUSTOMERS"
          />
          <Statistics
            :statistics-type="Metric.NEWCUSTOMERS" :interval-type="Interval.DAY" :date-filter="dateFilter"
            :key="keyPrefixDateFilter + Metric.NEWCUSTOMERS"
          />
        </div>
        <PortalFooter />
      </div>
    </div>
  </div>
</template>

<script>

import SidebarNav from "@/components/SidebarNav";
import Statistics from "@/components/common/Statistics";
import {portalCheckMixin} from "@/mixin/portalPageCheck";
import TableFilterOption from "@/components/common/TableFilterOption";
import { ComplianceFormStatus, Interval, Metric } from "@/api/paymentApi";
import {mapGetters} from "vuex";
import {navigateTo, navigateWithParam} from "@/router";
import SearchBar from "@/components/SearchBar";
import complianceForms from "@/api/complianceForms";
import PortalFooter from "@/components/portal/PortalFooter.vue";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {RouteConstants} from "@/router/routeConstants";
import {
  getDateTimeFromMillis,
  getDateTimeFromObject,
  getTimezoneName,
  getAbbreviatedMonth,
  getDateTimeFromObjectToUTCToJSDate, getDatePeriodFromFilter
} from "@/app/utils/common/functions/dateUtils";
import Formatter from "@/app/utils/common/functions/formatter";
import {balanceAccountMixin} from "@/mixin/balanceAccountMixin";
import {DateFilters} from "@/app/utils/common/constants";

export default {
  name: 'DashboardView',
  components: {
    MigrationHelper: () => import("@/components/onboarding/MigrationHelper.vue"),
    PortalFooter, SearchBar, SidebarNav, Statistics, TableFilterOption},
  mixins: [portalCheckMixin, balanceAccountMixin],
  data: () => {
    return {
      sidebarNavCollapsed: false,
      complianceForm: null,
      chargebackReserveAmount: null,
      dateFilter: {
        name: "one_week",
        startDate: undefined,
        endDate: undefined,
      },
      datepickerFilter: {
        startDate: undefined,
        endDate: undefined,
      },
      dateSelected:null,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    ...mapGetters('app', ["isAdminMode", 'getMerchantCurrency']),
    ...mapGetters('merchantBalanceReserve', ['getCurrentAmountInChargebackReserve']),
    ...mapGetters('merchantPayfacDetails', ['isEligibleForMigration']),
    ...mapGetters('businessAddress', ['getCountry']),
    getTimezoneName,
    Metric() {
      return Metric
    },
    Interval() {
      return Interval
    },
    keyPrefixDateFilter() {
      return this.dateFilter.startDate.getTime().toString() + this.dateFilter.endDate.getTime().toString()
    },
    keyPrefixDatepickerFilter() {
      return this.datepickerFilter.startDate.getTime().toString() + this.datepickerFilter.endDate.getTime().toString()
    },
  },
  created() {
    window.cbStorage.setItem('dateFilter', DateFilters.QUARTER_TO_DATE);
    ({ startDate: this.dateFilter.startDate, endDate: this.dateFilter.endDate } =
        getDatePeriodFromFilter(DateFilters.QUARTER_TO_DATE))
    this.datepickerFilter.startDate = this.setHourOfTheDay(getDateTimeFromObject(), 0);
    this.datepickerFilter.endDate = getDateTimeFromObjectToUTCToJSDate();
    this.dateSelected = getDateTimeFromObject().toMillis();
  },
  mounted() {
    this.portalChecks().then(() => {
      this.initialize();
    });
  },
  methods: {
    setHourOfTheDay: Formatter.setHourOfTheDay,
    async initialize() {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      });
      await this.loadComplianceForms();
      this.loadBalanceAccount();
    },
    navigateTo(route, dateFilter, ...tab) {
      if(route === 'transactions') {
        window.cbStorage.setItem("cb_payments_transaction_current_tab", tab)
      }
      navigateWithParam(route, {}, {...this.$route.query})
      window.cbStorage.setItem('dateFilter', dateFilter);
      window.cbStorage.setItem('dateFilter.startDate', this.dateFilter.startDate);
      window.cbStorage.setItem('dateFilter.endDate', this.dateFilter.endDate);
    },
    applyFilterDatepicker: function (dataSelected) {
      if (this.dateSelected === dataSelected) return ;
      this.datepickerFilter.startDate = this.setHourOfTheDay(getDateTimeFromMillis(dataSelected), 0);
      this.datepickerFilter.endDate = this.setHourOfTheDay(getDateTimeFromMillis(dataSelected), 24);
    },
    applyFilterInsights: function (filter) {
      this.dateFilter.name = filter.dateFilter
      this.dateFilter.startDate = filter.dateFilterValue.startDate;
      this.dateFilter.endDate = filter.dateFilterValue.endDate;
    },
    dateSelectorLimit: function (months) {
      return [new Date(getDateTimeFromObject().toISODate()),
        new Date(getDateTimeFromObject().plus({days: 30 * months}).toISODate())]
    },
    navigateToComplianceForSigning() {
      navigateWithParam(RouteConstants.COMPLIANCE_FORM_DETAILS, {complianceFormId: this.complianceForm.id},
          this.$route.query);
    },
    negativeReserveTitle() {
      return this.$t('dashboard.notifications.negativeReserve.title', {amount: Formatter.format({type: 'currency', data: this.chargebackReserveAmount, currencyCode: this.getMerchantCurrency})});
    },
    navigateToAddFunds() {
      navigateTo(RouteConstants.ADD_FUNDS, true, {...this.$route.query});
    },
    DatepickerDate() {
      const currentDate = new Date(this.datepickerFilter.startDate);
      const shortMonth = getAbbreviatedMonth(currentDate);
      return shortMonth + " " + this.datepickerFilter.startDate.getDate() + ", " + currentDate.getFullYear();
    },
    DatepickerDay() {
      return this.datepickerFilter.startDate.getTime() === this.setHourOfTheDay(getDateTimeFromMillis(this.dateSelected), 0).getTime() ? " (Today)" : "";
    },
    async exportToPDF() {

      const originalElement = this.$refs.contentToConvert;
      const margin = 20;

      const width = originalElement.offsetWidth + 2 * margin;
      const height = originalElement.offsetHeight + 2 * margin;

      const pdfWidth = width + 2 * margin;
      const pdfHeight = height + 2 * margin;

      const options = {
        backgroundColor: '#eaecee'
      }
      const canvas = await html2canvas(originalElement,options);

      let pdf = new jsPDF('p', 'px', [pdfWidth, pdfHeight]);
      pdf.setFillColor('#eaecee');
      pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');

      const imgWidth = width - 2 * margin;
      const imgHeight = height - 2 * margin;

      const imgData = canvas.toDataURL('image/jpeg',1); // Adjust compression quality (0.6 is 60% quality)
      pdf.addImage(imgData, 'JPEG',  margin, margin, imgWidth, imgHeight);

      pdf.save(this.DatepickerDate() + '.pdf');
    },
    loadBalanceAccount() {
      this.chargebackReserveAmount = this.getCurrentAmountInChargebackReserve;
    },
    async loadComplianceForms() {
      try {
        let response = await complianceForms.listComplianceForms(this.getMerchantId);
        this.complianceForm = response.compliance_forms.find(x => x.compliance_form_status === ComplianceFormStatus.INCOMPLETE
        && x.unsigned_file_id != null);
      } catch (e) {
        this.$danger(this.$t('profile.getComplianceFormFailed'));
      }
    },
  },
}

</script>

<style lang="scss">

.main-container {
  background: $neutral_50;
  height: 100%;
  overflow: scroll;
}

.dashboard-container {
  left: 256px;
  top: 0;
  position: relative;
  width: calc(100% - 256px);
  padding: 20px 30px 30px 30px;
  transition: all 0.2s ease-in;
  justify-content: center;
  display: flex;

  & .c-notification__title {
    margin: 0px !important;
    flex: unset !important;
  }

  & .c-notification__body {
    display: flex !important;
  }

  & .see-cf-details {
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    width: fit-content;
    color: $primary-600;
  }

  & .dashboard-sub-container {
    width: 100%;
    max-width: 1360px;
  }

  &.dashboard-container-sidebar-collapse {
    left: 86px;
    width: calc(100% - 86px);
  }

  & .table-header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: $neutral_1000;
    text-align: left;
    padding: 20px 0;
    display: flex;

    & .dashboard-title {
      flex: 95;
    }

    & .c-button {
      width: fit-content;
      height: 32px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      margin-left: 15px;
      display: flex;
      flex-direction: row;
      padding-right: 0px;
      padding-left: 0px;
      background: $primary_50 !important;
      border: 1px solid $primary_200;

      &:hover {
        color: $primary_600;
        border: 1px solid $primary_200;
      }
    }

    & .download-icon{
      padding-right: 4px;
    }
  }

  & .dashboard-today-data {
    display: flex;
    flex-direction: row;

    & .sub-data-container {
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      flex: 50;

      & .sub-data {
        display: flex;
        flex-direction: row;
        flex: 1;

        & .statistics-container:first-child {
          margin-right: 16px;
        }
      }

      & .sub-data:first-child {
        margin-bottom: 16px;
      }
    }
  }

  & .insight-header {
    font-style: normal;
    font-weight: $weight_medium;
    font-size: 16px;
    line-height: 30px;
    color: $neutral_600;
    text-align: left;
    margin-top: 32px;
    margin-bottom: 12px;
  }

  & .data-header {
    font-style: normal;
    font-weight: $weight_medium;
    font-size: 16px;
    line-height: 30px;
    color: $neutral_600;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 12px;
  }

  & .insight-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1px;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 0;

    & .data-container {
      border-radius: 0;
    }
  }

  & .date-picker {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 99;
    margin-right: 12px;
  }

  & .filter-button {
    border-radius: 6px;
    height: 32px;
    font-style: normal;
    font-size: 16px;
    padding: 0;
    border: none;
    background: none;
    box-shadow: none !important;

    & .datepicker-date {
      color: $neutral_600;
      font-weight: $weight_medium;
      line-height: 30px;
    }

    &:hover {
      background: none;
    }

    & .c-button__label {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-left: 0;
      padding-right: 12px;
    }

    & .filter-icon {
      margin-left: 8px;
      transform: rotate(0deg);
      transition: transform 1s linear;
    }

    & .filter-icon .open {
      transform: rotate(180deg);
      transition: transform 1s linear;
    }
  }
}
</style>