<template>
  <div class="dispute-response-view">
    <DisputeHeader
      :dispute="disputeDetails"
      :header="$t('disputes.actions.respondToDispute')"
      :add-funds-view="false"
    />
    <div class="dispute-response-form" v-if="disputeDetails !== null">
      <div class="dispute-overview" v-if="disputeDetails !== null">
        <div class="dispute-overview-title">
          Dispute Overview
        </div>
        <div class="dispute-overview-content">
          <div class="row">
            <FieldValue
              :field-name="$t('fieldNames.disputeType')"
              :field-value="disputeDetails.type"
            />
            <FieldValue :field-value="disputeDetails.reason" :field-name="$t('fieldNames.reason')" />
          </div>

          <FieldValue
            :field-name="$t('fieldNames.disputedAmount')"
            :field-value="
              format('currency', disputeDetails.amount, '', disputeDetails.currency)
                + ' '+ disputeDetails.currency"
          />
        </div>
      </div>
      <DisputeConsumerInput
        :dispute="disputeResponse"
        :update-name="(value) => disputeResponse.customer_name = value"
        :update-email="(value) => disputeResponse.customer_email = value"
      />
      <DisputeTypeOfPurchaseInput
        :value="disputeResponse.type_of_purchase"
        :choose-type-of-purchase="(value) => disputeResponse.type_of_purchase = value"
      />
      <DisputeDetailsDigitalProductsInput
        :error="disputeResponseValidationError[typeOfPurchase.DIGITALGOODS]"
        v-if="disputeResponse.type_of_purchase === typeOfPurchase.DIGITALGOODS"
        :dispute-response="disputeResponse"
        :update-dispute-detail="(key, value) =>{ updateDisputeResponseDigitalGoods(key, value); }"
      />
      <DisputeDetailsPhysicalGoodsInput
        :error="disputeResponseValidationError[typeOfPurchase.PHYSICALGOODS]"
        v-if="disputeResponse.type_of_purchase === typeOfPurchase.PHYSICALGOODS"
        :dispute-response="disputeResponse"
        :update-dispute-detail="(key, value) =>{ updateDisputeResponsePhysicalGoods(key, value); }"
      />
      <DisputeDetailsServicesInput
        v-if="disputeResponse.type_of_purchase === typeOfPurchase.SERVICE"
        :error="disputeResponseValidationError[typeOfPurchase.SERVICE]"
        :dispute-response="disputeResponse"
        :update-dispute-detail="(key, value) =>{ updateDisputeResponseServices(key, value); }"
      />

      <DisputeEvidenceUpload
        :dispute="disputeDetails"
        :update-dispute-detail="(fileIds) => updateDisputeResponseFileIds(fileIds)"
        :error="disputeResponseValidationError.evidence"
      />

      <div class="fee-info">
        <template v-if="disputeDetails.type !== 'INQUIRY'">
          <img src="@/app/assets/images/info-outline.svg" alt="Chargebee Info Icon" />
          <span>{{ $t('disputes.responseInfo') }}</span>
        </template>
      </div>
      <c-button variant="primary" class="submit-button" @click="submit" :loading="loading">
        Submit
      </c-button>
    </div>
  </div>
</template>
<script>
import {DisputeEvidenceDto, DisputeResponseDto} from "@/api/paymentApi";
import DisputeHeader from "@/components/disputes/DisputeHeader";
import disputes from "@/api/disputes";
import {portalCheckMixin} from "@/mixin/portalPageCheck";
import {mapActions, mapGetters} from "vuex";
import FieldValue from "@/components/common/FieldValue.vue";
import Formatter from "@/app/utils/common/functions/formatter";
import DisputeConsumerInput from "@/components/disputes/DisputeConsumerInput";
import DisputeTypeOfPurchaseInput from "@/components/disputes/DisputeTypeOfPurchaseInput";
import DisputeDetailsDigitalProductsInput from "@/components/disputes/DisputeDetailsDigitalProductsInput";
import DisputeDetailsServicesInput from "@/components/disputes/DisputeDetailsServicesInput";
import DisputeEvidenceUpload from "@/components/disputes/DisputeEvidenceUpload";
import DisputeDetailsPhysicalGoodsInput from "@/components/disputes/DisputeDetailsPhysicalGoodsInput";
import {goBack} from "@/router";
import {getName} from "@/app/utils/common/functions/functions";

export default {
  name: "DisputeResponseView",
  components: {
    DisputeDetailsPhysicalGoodsInput,
    DisputeConsumerInput, DisputeDetailsDigitalProductsInput, DisputeDetailsServicesInput,
    DisputeEvidenceUpload, FieldValue, DisputeHeader, DisputeTypeOfPurchaseInput,
  },
  mixins: [portalCheckMixin],
  props: {
    dispute: {
      type: DisputeResponseDto,
      required: true,
    },
  },
  data: function () {
    return {
      loading: false,
      disputeDetails: null,
      disputeResponse: null,
      disputeResponseValidationError: {
        [DisputeEvidenceDto.TypeOfPurchaseEnum.SERVICE]: {},
        [DisputeEvidenceDto.TypeOfPurchaseEnum.DIGITALGOODS]: {},
        [DisputeEvidenceDto.TypeOfPurchaseEnum.PHYSICALGOODS]: {},
        evidence: null,
      },
      typeOfPurchase: DisputeEvidenceDto.TypeOfPurchaseEnum,
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId']),
  },
  mounted() {
    this.portalChecks().then(() => {
      this.initialize();
    });
  },
  methods: {
    ...mapActions('disputes', ['updateDispute']),
    format(type, data, format, currencyCode) {
      return Formatter.format({type, data, format, currencyCode})
    },
    async initialize() {
      if (this.$route.params.data === undefined || this.$route.params.data === null) {
        this.disputeDetails = await disputes.fetchDisputeDetails(this.getMerchantId, this.$route.params.disputeId)
      } else {
        this.disputeDetails = this.$route.params.data
      }

      this.initialiseDisputeResponse()
    },
    submit: function () {
      if (!this.validateDisputeResponse()) {
        return
      }
      this.loading = true;
      this.disputeDetails.evidence_info = this.disputeResponse
      disputes.storeDisputeEvidenceDetails(this.getMerchantId, this.disputeDetails.id, this.disputeResponse)
          .then((response) => {
            this.$success(this.$t('disputes.evidenceSubmitted'))
            this.loading = false;
            this.disputeDetails = response
            this.updateDispute({id: this.disputeDetails.id, dispute: response})
            goBack()
          })
          .catch(e => {
            this.loading = false;
            this.$danger(e.source.title)
          })
    },
    typeOfPurchaseDetailsAttribute: function () {
      switch (this.disputeResponse.type_of_purchase) {
        case this.typeOfPurchase.DIGITALGOODS:
          return "digital_goods_details";
        case this.typeOfPurchase.PHYSICALGOODS:
          return "physical_goods_details";
        case this.typeOfPurchase.SERVICE:
          return "service_details";
      }
    },
    validateTypeOfPurchaseDetails: function () {
      let details = this.disputeResponse[this.typeOfPurchaseDetailsAttribute()];
      let result = Object.keys(details).reduce((error, key) =>
          !details[key] ? {...error, [key]: "Missing mandatory field"} : error, {});
      return {result, containsError: Object.keys(result).length !== 0}
    },
    validateEvidenceFiles: function () {
      let result = this.disputeResponse.file_evidences.files.length < 1 ?
          "Atleast one evidence file needs to be uploaded." : null;
      return {result, containsError: result !== null}
    },
    validateDisputeResponse() {
      for (let typeOfPurchaseKey in this.typeOfPurchase) {
        this.$set(this.disputeResponseValidationError,
            this.typeOfPurchase[typeOfPurchaseKey], {});
      }
      let typeOfPurchaseValidation = this.validateTypeOfPurchaseDetails();
      let evidenceFilesValidation = this.validateEvidenceFiles();
      this.$set(this.disputeResponseValidationError,
          this.disputeResponse.type_of_purchase, typeOfPurchaseValidation.result);
      this.$set(this.disputeResponseValidationError, 'evidence', evidenceFilesValidation.result);
      return !typeOfPurchaseValidation.containsError && !evidenceFilesValidation.containsError
    },
    initialiseDisputeResponse() {
      this.disputeResponse = {
        customer_name: getName(this.disputeDetails.consumer.first_name, this.disputeDetails.consumer.last_name),
        customer_email: this.disputeDetails.consumer.email,
        type_of_purchase: DisputeEvidenceDto.TypeOfPurchaseEnum.PHYSICALGOODS,
        physical_goods_details: {
          shipping_tracking_number: null,
          delivery_partner: null,
          purchase_details: null,
          refund_policy_details: null,
        },
        digital_goods_details: {
          purchase_date: null,
          purchase_details: null,
          refund_policy_details: null,
        },
        service_details: {
          service_date: null,
          purchase_date: null,
          type_of_service: null,
          description: null,
          refund_policy_details: null,
        },
        file_evidences: {
          files: [],
        },
      }
    },
    updateDisputeResponseDigitalGoods(key, value) {
      this.disputeResponse["digital_goods_details"][key] = value
    },
    updateDisputeResponsePhysicalGoods(key, value) {
      this.disputeResponse["physical_goods_details"][key] = value
    },
    updateDisputeResponseServices(key, value) {
      this.disputeResponse["service_details"][key] = value
    },
    updateDisputeResponseFileIds(value) {
      this.disputeResponseValidationError.evidence = null
      this.disputeResponse["file_evidences"]['files'] = value
    },
  },
}
</script>
<style lang="scss">

.dispute-response-view {
  padding-bottom: 200px;
}
.dispute-response-form {
  width: 700px;
  padding: 50px;
  margin: auto;

  .field {
    margin-top: 16px;
  }

  & .submit-button {
    width: 140px;
    float: left;
  }

  & .fee-info {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    margin-top: 60px;
    margin-bottom: 16px;
    color: #374049;
  }

  & .dispute-overview {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    margin-top: 70px;

    & .dispute-overview-title {
      font-size: 16px;
      font-weight: $weight_bold;
    }

    & .dispute-overview-content {
      & .row {
        display: flex;
        width: 100%;

        & div {
          flex: 1;
        }
      }
    }
  }

}
</style>


