import {TransactionType} from "@/api/paymentApi";

export function isNegativeAmountType(transactionType: TransactionType): boolean {
    return [TransactionType.ACHRETURN,
        TransactionType.PAYMENTRETURN,
        TransactionType.REFUND,
        TransactionType.PAYMENTREVERSAL,
        TransactionType.CHARGEBACKDEBIT,
        TransactionType.CHARGEBACKFEE,
        TransactionType.RESERVEADJUSTMENTDEBIT].includes(transactionType);
} 

export function getTransactionTypeDisplayName(transactionType: TransactionType): string {
    switch (transactionType) {
        case TransactionType.REFUND:
          return 'Refund';
        case TransactionType.ACHRETURN:
          return 'ACH Return';
        case TransactionType.PAYMENTRETURN:
            return 'Payment Return';
        case TransactionType.PAYMENTREVERSAL:
            return 'Payment Reversal';
        case TransactionType.CHARGEBACKDEBIT:
        case TransactionType.CHARGEBACKFEE:
        case TransactionType.RESERVEADJUSTMENTDEBIT:
            return "Debit";
        case TransactionType.CHARGEBACKCREDIT:
        case TransactionType.RESERVEADJUSTMENTCREDIT:
            return 'Credit';
        case TransactionType.RESERVE:
        case TransactionType.RESERVETRANSFER:
          return 'Reserve';
        default:
          return 'Payment';
      }
}